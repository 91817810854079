import React, { useState, useEffect } from "react";
import axios from "axios";

import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import ErrorBoundary from "../components/ErrorBoundary";
import NewSuggestionItem from "../components/NewSuggestionItem/NewSuggestionItem";
import SuggestionLink from "../components/SuggestionLink/SuggestionLink";
import repurchaseGraph from "../assets/repurchase-graph.svg";
import repurchaseGraphic from "../assets/repurchase-graphic.svg";
import styles from "../styles/components/_suggestion-section.scss";
import SVG from "react-inlinesvg";
import { getUserSex } from "../helpers";
import { useTranslation, Trans } from "react-i18next";
import env from "../env";
import { useHistory } from "react-router-dom";

const SuggestionSection = ({
  identityNumber,
  activeOrder,
  colorCodes,
  activeResultCode,
  selectedLanguage,
  withBottomSectionStyling = true,
  hideTestSuggestions = false,
  requireActiveOrder = true,
  hideEditableSuggestions = false,
  orders,
}) => {
  let repurchaseUrl =
    env.APP_ID === "puhti"
      ? "https://www.puhti.fi/tuotteet/"
      : "https://vital.se/halsokontroller/"; //`${env.WEBSHOP_URL}/tuotteet/`;

  if (
    activeOrder &&
    activeOrder.repurchase_product_ids &&
    activeOrder.repurchase_product_ids.length
  ) {
    /*
    legacy, might be used at some point again
    repurchaseUrl =
      env.APP_ID === "puhti"
        ? `${
            env.WEBSHOP_URL
          }/ostoskori/?add-to-cart=${activeOrder.repurchase_product_ids.toString()}`
        : `${
            env.WEBSHOP_URL
          }/varukorg/?add-to-cart=${activeOrder.repurchase_product_ids.toString()}`;
          */

    repurchaseUrl =
      env.APP_ID === "puhti"
        ? `${env.WEBSHOP_URL}/tuotteet`
        : `${env.WEBSHOP_URL}/halsokontroller`;
  }

  const groupTestCodes = (activeOrder?.groups || [])
    .flatMap((group) => group.results?.map((r) => r.code))
    .filter((value) => value);

  const orderedTestCodes = (activeOrder?.ordered_tests || []).map(
    (test) => test.code,
  );

  const renderTestSuggestions = !requireActiveOrder
    ? true
    : activeOrder &&
      activeOrder.groups &&
      activeOrder.groups.length > 0 &&
      activeOrder.ordered_tests &&
      activeOrder.ordered_tests.length === 0;

  const allTestCodes = [...groupTestCodes, ...orderedTestCodes];

  return (
    <ErrorBoundary>
      <>
        <section
          className={`${withBottomSectionStyling ? "bottom-section" : ""}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <EditableSuggestions
                  orders={orders}
                  selectedLanguage={selectedLanguage}
                  hideEditableSuggestions={hideEditableSuggestions}
                />

                {renderTestSuggestions && (
                  <TestSuggestions
                    identityNumber={identityNumber}
                    activeOrder={activeOrder}
                    colorCodes={colorCodes}
                    requireActiveOrder={requireActiveOrder}
                    allTestCodes={allTestCodes}
                    selectedLanguage={selectedLanguage}
                  />
                )}

                {!hideTestSuggestions && (
                  <div className="margin-2 margin-top">
                    <div className="row">
                      <RepurchaseSection
                        activeOrder={activeOrder}
                        activeResultCode={activeResultCode}
                        repurchaseUrl={repurchaseUrl}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {!hideTestSuggestions && env.APP_ID === "puhti" && <PromotionsBanner />}
      </>
    </ErrorBoundary>
  );
};

const EditableSuggestions = ({
  orders,
  selectedLanguage,
  hideEditableSuggestions,
}) => {
  const [suggestionItems, setSuggestionItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const formatData = (data) => {
    let groups = [];

    data.data.forEach((datum) => {
      if (datum.attributes) {
        if (hideEditableSuggestions && !datum.attributes.visible_on_report) {
          //skip add if suggestions should be hidden and add is not an excpetion set by admin
          return;
        }

        if (datum.attributes.requires_order && orders?.length === 0) {
          // Skip the advertisement if there's no activeOrder (e.g. on logout page).
          return;
        }

        const candidate = groups.find(
          (group) =>
            group && group.title === datum.attributes.advert_group_title,
        );
        if (candidate) {
          candidate.items.push(datum.attributes);
        } else {
          const matchedGroup =
            data.included &&
            data.included.find(
              (group) =>
                group.attributes &&
                group.attributes.title === datum.attributes.advert_group_title,
            );
          groups.push({
            title: datum.attributes.advert_group_title,
            description: matchedGroup
              ? matchedGroup.attributes.description
              : null,
            position: matchedGroup ? matchedGroup.attributes.position : null,
            items: [datum.attributes],
          });
        }
      }
    });

    groups.sort((a, b) => a.position - b.position);
    groups.forEach((group) => {
      group.items.sort((a, b) => a.position - b.position);
    });

    return groups;
  };

  useEffect(() => {
    axios
      .get(`/api/advertisements?language=${selectedLanguage}`)
      .then((response) => {
        setSuggestionItems(formatData(response.data));
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [orders, selectedLanguage]);

  if (loading) {
    return (
      <div className="flex justify-center margin-2 margin-top">
        <LoadingSpinner text={t("suggestion_section.loading_results")} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center margin-2 margin-top">
        <ErrorMessage />
      </div>
    );
  }

  return (
    <>
      {suggestionItems &&
        suggestionItems.length > 0 &&
        suggestionItems.map((group, index) => {
          return (
            <div
              className="margin-2 margin-top"
              key={group.title + "_" + index}
            >
              <h2 className="size-huge color-purple weight-500 margin-0-25 margin-bottom">
                {group.title}
              </h2>
              <div className="color-purple size-medium margin-1-25 margin-bottom">
                {group.description}
              </div>
              <div className="row">
                {group.items.map((item, index) => {
                  return (
                    <NewSuggestionItem
                      key={item.title + "_" + index}
                      title={item.title}
                      image={item.image_url}
                      content={item.content}
                      link={item.link_url}
                      linkText={item.link_text}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
};

const RepurchaseSection = ({
  activeOrder,
  activeResultCode,
  repurchaseUrl,
}) => {
  const testCodesToShowOn = ["1220", "1137", "1142", "2303", "1552", "1395"];
  const { t } = useTranslation();
  if (
    activeOrder ||
    (activeResultCode && testCodesToShowOn.includes(activeResultCode))
  ) {
    return (
      <div className="col-xs-12  flex-y">
        <h2 className="size-huge color-purple weight-500 margin-1-25 margin-bottom">
          {t("suggestion_section.follow_development")}
        </h2>

        <RepurchaseSuggestion repurchaseUrl={repurchaseUrl} />
      </div>
    );
  } else {
    return "";
  }
};

const PromotionsBanner = () => {
  const { t } = useTranslation();
  const history = useHistory();

  if (history == undefined) {
    // User is logged out, so we should not offer redirect to promotions page
    return null;
  }

  return (
    <>
      <div
        className={"promotions-banner-container"}
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="col-xs-12">
            <h2 className="size-huge color-purple weight-500 margin-0-75 margin-bottom">
              {t("promotions.puhti_promotions_and_services")}
            </h2>
            <p className="color-black weight-500 margin-0-75 margin-bottom">
              {t("promotions.see_promotion_and_services_for_customers")}.
            </p>
            <button
              className="button button--white button--white_outlined"
              style={{ width: "auto" }}
              onClick={() => {
                history.push("/promotions");
              }}
            >
              {t("promotions.go_to_promotions")}
            </button>
          </div>
        </div>
        <SVG
          src={repurchaseGraphic}
          className="repurchase-graphic"
          style={{ position: "absolute", right: -50, top: 50 }}
        />
      </div>
    </>
  );
};

const TestSuggestions = ({
  identityNumber,
  activeOrder,
  colorCodes,
  allTestCodes,
  selectedLanguage,
}) => {
  const [recommendedTests, setRecommendedTests] = useState(null);
  const userSex = getUserSex(identityNumber || "");
  const { t } = useTranslation();

  useEffect(() => {
    function fetchRecommendedTests() {
      axios
        .get(`/api/recommended_tests?language=${selectedLanguage}`)
        .then((response) => {
          setRecommendedTests(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fetchRecommendedTests();
  }, [selectedLanguage]);

  let suggestedTests = null;

  if (
    activeOrder &&
    activeOrder.groups &&
    activeOrder.groups.length > 0 &&
    allTestCodes
  ) {
    const results = activeOrder.groups.flatMap((group) => {
      return group.results;
    });

    const resultCodes = results.map((result) => result.code);

    suggestedTests = recommendedTests?.data?.filter(
      (suggestion) =>
        !resultCodes.some((r) => r === suggestion.attributes.code) &&
        !allTestCodes.some(
          (testCode) => testCode === suggestion.attributes.code,
        ) &&
        (userSex === suggestion.attributes.gender ||
          suggestion.attributes.gender === "both"),
    );
  }

  if (suggestedTests && suggestedTests.length > 0) {
    return (
      <div className="margin-2 margin-top">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="size-huge color-purple weight-500">
              {t("suggestion_section.what_tests_next")}
            </h2>
            <p className="size-medium margin-1 margin-bottom">
              {t("suggestion_section.popular_tests_purchase")}
            </p>
          </div>
        </div>
        <div className="row">
          {suggestedTests?.map((suggestion, index) => {
            const { id } = suggestion;
            const { title, button_text, url, description } =
              suggestion.attributes;

            return (
              <div className="col-xs-12 col-s-6 col-l-4 flex-y" key={id}>
                <div
                  className="suggestion-item card"
                  style={{
                    borderTopWidth: "0.75rem",
                    borderTopStyle: "solid",
                    borderTopColor: colorCodes
                      ? colorCodes[index % colorCodes.length]
                      : "#39235d",
                  }}
                >
                  <div className="suggestion-item__main">
                    <h4 className="size-medium color-purple semibold margin-0-5 margin-bottom">
                      {title}
                    </h4>
                    <p>{description}</p>
                  </div>
                  <SuggestionLink link={url} linkText={button_text} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const SuggestionItem = ({ children, buttonText, buttonUrl }) => {
  return (
    <div className="suggestion-item card col-xs-12 col-m-6 flex-y">
      <div className="suggestion-item__main">{children}</div>
      {buttonText && buttonUrl && (
        <SuggestionLink link={buttonUrl} linkText={buttonText} />
      )}
    </div>
  );
};

export const RepurchaseSuggestion = ({ repurchaseUrl }) => {
  const { t } = useTranslation();

  return (
    <div className="card margin-2 margin-bottom responsive-suggestion-card">
      <div className="responsive-suggestion-card_left">
        <h3 className="size-large semibold color-purple margin-1 margin-bottom">
          {t("repurchase_suggestion.title")}
        </h3>

        <div className="repurchase-icon-container">
          <SVG src={repurchaseGraph} />
        </div>

        <div className="margin-1 margin-top">
          <a
            target="_blank"
            className="color-purple semibold"
            href={t("repurchase_suggestion.how_to_prepare_link")}
            id="how-to-prepare-link-left"
          >
            {t("repurchase_suggestion.how_to_prepare")}
          </a>
        </div>
      </div>
      <div className="flex-1">
        <div className="desktop-1-5-padding">
          <Trans i18nKey="repurchase_suggestion.body">
            <p
              className="margin-1 margin-bottom color-dark weight-500"
              style={{ textAlign: "center" }}
            />
            <p
              className="color-dark weight-500"
              style={{ textAlign: "center" }}
            />
          </Trans>
          <a
            className="color-purple semibold margin-1 margin-top"
            href="/"
            id="how-to-prepare-link-right"
          >
            {t("repurchase_suggestion.how_to_prepare")}
          </a>
        </div>
        <div className="margin-1 margin-top">
          <SuggestionLink
            link={repurchaseUrl}
            linkText={t("repurchase_suggestion.button_text")}
            filled
            underline={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SuggestionSection;
